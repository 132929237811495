import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./index/index.module').then(m => m.IndexPageModule)
  },
  {
    path: '',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },
  { path: 'quiz', loadChildren: './pages/quiz/quiz.module#QuizPageModule' },  { path: 'quizresult', loadChildren: './pages/quizresult/quizresult.module#QuizresultPageModule' },
  { path: 'modalresult', loadChildren: './pages/modalresult/modalresult.module#ModalresultPageModule' },
  { path: 'quizview', loadChildren: './pages/quizview/quizview.module#QuizviewPageModule' },
  { path: 'nilai', loadChildren: './pages/nilai/nilai.module#NilaiPageModule' }


];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
